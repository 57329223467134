module.exports = {
  keyList: {
    projectName: 'Raven-AI-web',
    projectUrl: '',
    // apiURL: 'http://192.168.1.7:8091/teqverse-api/',
    // grpcURL: 'http://192.168.1.7:8000',
    apiURL: "https://api.teqbahn.com/teqverse-api/",
    grpcURL: 'https://api.teqbahn.com',
  },
};
