import React from "react";
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import AIAgentSolutions from './AIAgentSolutions';
import Menu from '../pages/Menu/Menu';
import Footer from './Footer';
import MyConstant from '../config/MyConstant';
import TailoredPage from '../pages/TailoredPage';
import ContactUs from "./ContactUs";


const UserRoute = (props) => {
    const path = MyConstant.keyList.projectUrl
    function linkClick() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return (
        <>
            <Menu path={path} linkClick={linkClick} />
            <div style={{ paddingTop: 50 }}>
                <Routes>
                    <Route exact path={"/"} element={<Home />} />
                    <Route exact path={"/home"} element={<Home />} />
                    <Route exact path={"/about"} element={<About />} />
                    <Route exact path={"/ai-agent-solutions"} element={<AIAgentSolutions />} />
                    <Route exact path={"/tailored"} element={<TailoredPage />} />
                    <Route exact path={"/contact-us"} element={<ContactUs grpcClient={props.grpcClient} />} />
                    <Route exact path={"*"} element={<Home />} />
                </Routes>
                <Footer path={path} grpcClient={props.grpcClient} />
            </div>
        </>
    );
};

export default UserRoute;
