import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import "./Menu.css";
import Relume from "../../assets/Menu/Relume.png"
import Logo from "../../assets/Menu/Logo.png"
// import Placeholder from "../../assets/Menu/Placeholder.png"
import LinkedIn from "../../assets/imges/LinkedIn.png"
import X from "../../assets/imges/X.png"
import LinkedIn_2 from "../../assets/imges/LinkedIn_2.png"
import X_2 from "../../assets/imges/X_2.png"
import MyConstant from "../../config/MyConstant";

const resourcesMenu = [
    {
        label: "Explore Our Solutions",
        child: [
            {
                label: "AI Agents",
                description: "Discover how AI can transform your business.",
                url: "ai-agent-solutions"
            },
            {
                label: "Consulting Services",
                description: "Expert guidance for your AI journey.",
                url: "tailored"
            },
            // {
            //     label: "Case Studies",
            //     description: "See our success stories and insights.",
            //     url: ""
            // },
            {
                label: "Contact Us",
                description: "Get in touch for personalized support.",
                url: "contact-us"
            },
        ]
    },
    // {
    //     label: "Latest Insights",
    //     child: [
    //         {
    //             label: "Blog Posts",
    //             description: "Stay updated with our latest articles.",
    //             url: ""
    //         },
    //         {
    //             label: "Webinars",
    //             description: "Join our expert-led sessions for learning.",
    //             url: ""
    //         },
    //         {
    //             label: "Events",
    //             description: "Participate in our upcoming events.",
    //             url: ""
    //         },
    //         {
    //             label: "Newsletter",
    //             description: "Subscribe for the latest updates.",
    //             url: ""
    //         },
    //     ]
    // },
    // {
    //     label: "Popular Topics",
    //     child: [
    //         {
    //             label: "AI Trends",
    //             description: "Explore the latest in AI technology.",
    //             url: ""
    //         },
    //         {
    //             label: "Tech Innovations",
    //             description: "Discover cutting-edge advancements in AI.",
    //             url: ""
    //         },
    //         {
    //             label: "Customer Stories",
    //             description: "Learn how clients benefit from our solutions.",
    //             url: ""
    //         },
    //         {
    //             label: "FAQs",
    //             description: "Find answers to common questions.",
    //             url: ""
    //         },
    //     ]
    // },
]
const Menu = ({ path, linkClick: parentLinkClick }) => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false); // For mobile toggler
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For dropdown
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);


    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    }, [isMenuOpen]);

    useEffect(() => {
        setIsDropdownOpen(false)
    }, [location]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const linkClick = () => {
        setIsMenuOpen(false);
        parentLinkClick()
    };



    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top">
            <div className="container-fluid">
                <Link onClick={() => linkClick()} to={path + "/home"} className="navbar-brand">
                    <img src={Logo} width="30" height="30" className="d-inline-block align-top" alt="Logo"></img> Raven AI
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleMenu}
                    aria-expanded={isMenuOpen}
                    aria-label="Toggle navigation"
                >
                    <span className={`fas fa-${isMenuOpen ? "times" : "bars"}`}></span>
                </button>

                <div
                    className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""
                        }`}
                >
                    <ul className="navbar-nav w-100">
                        {/* <li className="nav-item">
                            <Link onClick={() => linkClick()} to={path + "/home"} className="nav-link active">
                                Home Page
                            </Link>
                        </li> */}

                        <li className="nav-item">
                            <Link onClick={() => linkClick()} to={path + "/about"} className="nav-link" href="#">
                                About Us
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link onClick={() => linkClick()} to={path + "/home"} className="nav-link" href="#">
                                Services
                            </Link>
                        </li> */}

                        <li
                            className={`nav-item dropdown custom ${isDropdownOpen ? "show" : ""
                                }`}
                            // onMouseEnter={handleMouseEnter}
                            // onMouseLeave={handleMouseLeave}
                            onClick={toggleDropdown}
                        >
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                aria-expanded={isDropdownOpen}
                                onClick={(e) => e.preventDefault()}
                            >
                                Explore Our Solutions
                            </a>
                            <div className={`dropdown-menu d-flex resource-dropdown ${isDropdownOpen ? "show" : ""}`}>
                                {
                                    resourcesMenu.map((parent, index) => {
                                        let { label, child } = parent
                                        return <div className={`parent-label px-3 my-3 ${windowWidth > 650 ? " d-flex flex-1 align-items-center" : "flex-column align-items-start"} `} key={index}>
                                            {/* <div className="py-2">{label}</div> */}
                                            {
                                                child.map((item, cIndex) => {
                                                    let { label, description } = item
                                                    return <div className="child-item p-2 my-1 " key={cIndex}>
                                                        <Link onClick={() => linkClick()} className="d-flex" to={item.url ? path + "/" + item.url : path + "/home"} >
                                                            <div>
                                                                <img className="img-fluid" src={Relume} alt={"Raven AI"} />
                                                            </div>
                                                            <div className="px-2">
                                                                <b>{label}</b>
                                                                <div className="m-hide">{description}</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    })
                                }
                                <div className="parent-label  px-3  py-3 from-our-blog ">
                                    <div className="py-2">{"Social"}</div>
                                    <div className="py-2">
                                        <Link to="https://www.linkedin.com/company/raven-ai-agents" target="_blank" rel="noopener noreferrer" >
                                            <img className="img-fluid mx-2" width={50} src={LinkedIn_2} alt={"Raven AI"} />
                                        </Link>
                                        <Link to="https://x.com/raven_ai_agents" target="_blank" rel="noopener noreferrer" >
                                            <img className="img-fluid mx-2" width={50} src={X_2} alt={"Raven AI"} />
                                        </Link>
                                    </div>
                                    {/* <div className="child-item py-3">
                                        <b>AI Revolution</b>
                                        <div>
                                            How AI is changing customer service forever.
                                            <div><Link onClick={() => linkClick()} to={path + "/home"}>Read more</Link></div>
                                        </div>
                                        <div className="py-3">
                                            Contact Us <i className="fas fa-angle-right"></i>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <form className="end-button d-flex" onSubmit={(event) => {
                        event.preventDefault();
                    }}>
                        <button style={{ width: 110 }} className="btn btn-learn mx-2" onClick={() => {
                            linkClick()
                            navigate(MyConstant.keyList.projectUrl + "/contact-us");
                        }}>Contact Us</button>
                    </form>
                </div>
            </div>
        </nav>
    );
};

export default Menu;
