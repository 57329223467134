import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

// import empowerimage from "../assets/imges/empowerimage.png"
import Relume from "../assets/imges/Relume.png"
import OurTeam from "../assets/imges/OurTeam.png"
import LinkedIn from "../assets/imges/LinkedIn.png"
import X from "../assets/imges/X.png"
import Dribble from "../assets/imges/Dribble.png"
// import WebFlow from "../assets/imges/WebFlow.png"
// import PlaceholderRectangle from "../assets/imges/PlaceholderRectangle.png"
import RavenBlack_w_616_h_640 from "../assets/imges/RavenBlack_w_616_h_640.png"
import RavenWhite_w_616_h_640 from "../assets/imges/RavenWhite_w_616_h_640.png"
import RavenWhite_w_833_h_516 from "../assets/imges/RavenWhite_w_833_h_516.png"
import MyConstant from '../config/MyConstant';







const About = () => {
    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);


    return <div className='container-fluid about-us'>
        <div className={windowWidth < 500 ? "px-2 py-5" : 'p-5'}>
            <h2>Empowering AI Solutions</h2>
            Transforming customer service and communication through innovative AI technology for a seamless experience.
        </div>
        <div className={"row py-5"}>
            <div className='col-sm-6'>
                <div className={`d-flex flex-column justify-content-center h-100 ${windowWidth < 500 ? "px-2 py-5" : 'p-5'}`}>
                    <h5>Empowering</h5>
                    <h1>Innovating the Future of AI Agents</h1>
                    <p>At Raven AI, we are dedicated to transforming how businesses interact with technology. Our vision is to create intelligent agents that enhance efficiency and elevate customer experiences across various platforms.</p>

                    <div className=''>
                        {/* <button className='btn btn-learn-more'>Learn More</button> */}
                        <button className='btn btn-learn Us mx-2' onClick={() => {
                            navigate(MyConstant.keyList.projectUrl + "/contact-us");
                        }}>Contact Us </button>
                    </div>
                </div>
            </div>
            <div className='col-sm-6'>
                <div className={windowWidth < 500 ? "px-2 py-5" : 'p-5'}>
                    <img src={RavenWhite_w_616_h_640} className='img-fluid img-bg-clr' alt="img" />
                </div>
            </div>
        </div>
        <div className='row py-5'>
            <div className='col-sm-6'>
                <div className={`d-flex flex-column justify-content-center h-100 ${windowWidth < 500 ? "px-2 py-5" : 'p-5'}`}>
                    <div>
                        <img className='img-fluid' src={Relume} alt={"Raven"} />
                    </div>
                    <h1>Transforming Customer Engagement with AI Agents</h1>
                    <p>Raven AI Agents revolutionize business operations by providing 24/7 support across multiple channels, enhancing customer satisfaction and efficiency. Their ability to handle various tasks—from inquiries to transactions—ensures that businesses can scale seamlessly while maintaining a personal touch.</p>

                    <div className=''>
                        {/* <button className='btn btn-learn-more'>Learn More</button> */}
                        <button className='btn btn-learn mx-2' onClick={() => {
                            navigate(MyConstant.keyList.projectUrl + "/contact-us");
                        }}>Contact Us</button>
                    </div>
                </div>
            </div>
            <div className='col-sm-6'>
                <div className={windowWidth < 500 ? "px-2 py-5" : 'p-5'}>
                    <img src={RavenBlack_w_616_h_640} className='img-fluid' alt="img" />
                </div>
            </div>
        </div>
        <div className='row py-5'>
            <div className='col-sm-12'>
                <div className={`d-flex flex-column justify-content-center h-100  ${windowWidth < 500 ? "px-2 py-5" : 'p-5'}`}>
                    <h5>Innovators</h5>
                    <h1>Our Team</h1>
                    <p>Meet the visionaries behind Raven AI's success.</p>
                </div>
            </div>
        </div>
        <div className='row px-5'>
            <div className='col-sm-3 my-2'>
                <img className='img-fluid' src={OurTeam} alt={"Raven AI"} />
                <div className='my-3'>
                    <b>Chris Thomas</b>
                    <div>Co-Founder</div>
                    <div className='mt-3'>
                        An AI Engineer with 15 years of experience in finance and innovation technology.
                    </div>
                </div>
                <div className='link'>
                    <img className='img-fluid mx-2' src={LinkedIn} alt={"linkedin"} />
                    <img className='img-fluid mx-2' src={X} alt={"X"} />
                    <img className='img-fluid mx-2' src={Dribble} alt={"dribble"} />
                </div>
            </div>
            <div className='col-sm-3 my-2'>
                <img className='img-fluid' src={OurTeam} alt={"Raven AI"} />
                <div className='my-3'>
                    <b>Ravi Madhusudhan</b>
                    <div>Co-Founder</div>
                    <div className='mt-3'>
                        An Aeronautical Engineer with over 30 years in system architecture and AI computing.
                    </div>
                </div>
                <div className='link'>
                    <img className='img-fluid mx-2' src={LinkedIn} alt={"linkedin"} />
                    <img className='img-fluid mx-2' src={X} alt={"X"} />
                    <img className='img-fluid mx-2' src={Dribble} alt={"dribble"} />
                </div>
            </div>
            <div className='col-sm-3 my-2'>
                <img className='img-fluid' src={OurTeam} alt={"Raven AI"} />
                <div className='my-3'>
                    <b>Team Vision</b>
                    <div>Leadership Role</div>
                    <div className='mt-3'>
                        Driving innovation in AI technology and consulting services for diverse industries.
                    </div>
                </div>
                <div className='link'>
                    <img className='img-fluid mx-2' src={LinkedIn} alt={"linkedin"} />
                    <img className='img-fluid mx-2' src={X} alt={"X"} />
                    <img className='img-fluid mx-2' src={Dribble} alt={"dribble"} />
                </div>
            </div>
            <div className='col-sm-3 my-2'>
                <img className='img-fluid' src={OurTeam} alt={"Raven AI"} />
                <div className='my-3'>
                    <b>Future Focus</b>
                    <div>Strategic Growth</div>
                    <div className='mt-3'>
                        Committed to expanding AI capabilities and enhancing customer experiences across multiple channels.
                    </div>
                </div>
                <div className='link'>
                    <img className='img-fluid mx-2' src={LinkedIn} alt={"linkedin"} />
                    <img className='img-fluid mx-2' src={X} alt={"X"} />
                    <img className='img-fluid mx-2' src={Dribble} alt={"dribble"} />
                </div>
            </div>
        </div>
        <div className='row px-5 my-3'>
            <div className='col-sm-3 my-2'>
                <img className='img-fluid' src={OurTeam} alt={"Raven AI"} />
                <div className='my-3'>
                    <b>Join Us</b>
                    <div>Career Opportunities</div>
                    <div className='mt-3'>
                        Be part of a pioneering team that shapes the future of AI technology.
                    </div>
                </div>
                <div className='link'>
                    <img className='img-fluid mx-2' src={LinkedIn} alt={"linkedin"} />
                    <img className='img-fluid mx-2' src={X} alt={"X"} />
                    <img className='img-fluid mx-2' src={Dribble} alt={"dribble"} />
                </div>
            </div>
            <div className='col-sm-3 my-2'>
                <img className='img-fluid' src={OurTeam} alt={"Raven AI"} />
                <div className='my-3'>
                    <b>We're Hiring!</b>
                    <div>Join Us</div>
                    <div className='mt-3'>
                        Explore exciting career opportunities and contribute to groundbreaking AI solutions.
                    </div>
                </div>
                <div className='link'>
                    <img className='img-fluid mx-2' src={LinkedIn} alt={"linkedin"} />
                    <img className='img-fluid mx-2' src={X} alt={"X"} />
                    <img className='img-fluid mx-2' src={Dribble} alt={"dribble"} />
                </div>
            </div>
            <div className='col-sm-3 my-2'>
                <img className='img-fluid' src={OurTeam} alt={"Raven AI"} />
                <div className='my-3'>
                    <b>Open Positions</b>
                    <div>Explore Now</div>
                    <div className='mt-3'>
                        Check out our current openings and take the next step in your career.
                    </div>
                </div>
                <div className='link'>
                    <img className='img-fluid mx-2' src={LinkedIn} alt={"linkedin"} />
                    <img className='img-fluid mx-2' src={X} alt={"X"} />
                    <img className='img-fluid mx-2' src={Dribble} alt={"dribble"} />
                </div>
            </div>


            <div className='col-sm-3 my-2'>
                <img className='img-fluid' src={OurTeam} alt={"Raven AI"} />
                <div className='my-3'>
                    <b>Join Us</b>
                    <div>Be Part</div>
                    <div className='mt-3'>
                        Join a team that values creativity, innovation, and collaboration in the AI space.
                    </div>
                </div>
                <div className='link'>
                    <img className='img-fluid mx-2' src={LinkedIn} alt={"linkedin"} />
                    <img className='img-fluid mx-2' src={X} alt={"X"} />
                    <img className='img-fluid mx-2' src={Dribble} alt={"dribble"} />
                </div>
            </div>
        </div>

        {/* <div className={`row ${windowWidth < 500 ? "px-2 py-5" : 'p-5'}`}>
            <div className='col-sm-12'>
                <h2>We're Hiring!</h2>
                <div className='my-3'>Discover your future with us at Raven AI.</div>
                <button className='btn mb-3 btn-open-positions'>Open Positions</button>
            </div>
        </div> */}
        <div className={`row ${windowWidth < 500 ? "px-2 py-5" : 'p-5'}`}>
            <div className='offset-sm-3 col-sm-6 text-center'>
                {/* <img className='img-fluid' src={WebFlow} alt='webflow' /> */}
                <div className='my-3'>
                    <h2>"Raven AI transformed our customer service operations. Their AI agents are efficient, reliable, and have significantly improved our response times."</h2>
                </div>
                <div className='my-3 text-center'>
                    <img className='img-fluid' src={OurTeam} alt={"Raven AI"} />
                    <div className='mt-3'><b>Alex Johnson</b></div>
                    <div>Director, Tech Solutions</div>
                </div>
            </div>
        </div>
        <div className={`row ${windowWidth < 500 ? "px-2 py-5" : 'p-5'}`}>
            <div className='col-sm-12'>
                <h1>Unlock Your Business Potential</h1>
                <div className='my-3'>
                    Discover how our AI solutions can transform your operations and enhance customer experiences today.
                </div>
                <div className='my-3'>
                    {/* <button className='btn btn-learn-more-black'>Learn More</button> */}
                    <button className='btn btn-learn-more mx-2 bg-black text-white' onClick={() => {
                        navigate(MyConstant.keyList.projectUrl + "/contact-us");
                    }}
                    >Contact Us</button>
                </div>
            </div>
        </div>
        {/* <div className={`row ${windowWidth < 500 ? "px-2 py-5" : 'p-5'}`}>
            <div className='col-sm-12'>
                <b>Connect</b>
                <h1>Contact Us</h1>
                <div className='my-3'>
                    We'd love to hear from you!
                </div>
            </div>
        </div>
        <div className={`row contact-us ${windowWidth < 500 ? "px-2 py-5" : 'p-5'}`}>
            <div className='col-sm-4'>
                <div>
                    <i className="far fa-envelope"></i>

                    <div className='my-2'>
                        <b>Email</b>
                    </div>
                    <div className='my-2'>
                        Reach us anytime at
                    </div>
                    <div className='my-2'>
                        <a href="mailto:hello@ravenai.com">hello@ravenai.com</a>
                    </div>
                </div>
                <div className='my-5'>
                    <i className="fas fa-phone-alt"></i>

                    <div className='my-2'>
                        <b>Phone</b>
                    </div>
                    <div className='my-2'>
                        Call us at
                    </div>
                    <div className='my-2'>
                        <a href="tel:+15551234567">+1 (555) 123-4567</a>
                    </div>
                </div>
                <div className='my-5'>
                    <i className="fas fa-map-marker-alt"></i>

                    <div className='my-2'>
                        <b>Office</b>
                    </div>
                    <div className='my-2'>
                        456 Innovation Way, San Francisco, CA 94105
                    </div>
                    <div className='my-2'>
                        <a href="">Get Directions</a>
                    </div>
                </div>
            </div>
            <div className='col-sm-8'>
                <img src={RavenWhite_w_833_h_516} className='img-fluid img-bg-clr' alt="img" />
            </div>
        </div> */}
    </div>;
};

export default About;