import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import UserRoute from './pages/UserRoute';
import MyConstant from './config/MyConstant';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const {
  ZiFetchService1Client,
  ZiFetchService2Client,
  ZiFetchService3Client,
  ZiFetchService4Client,
  ZiFetchService5Client,
  ZiFetchService6Client,
  ZiFetchService7Client,
  ZiFetchService8Client,
  ZiFetchService9Client,
  ZiFetchService10Client,
} = require('./protobuf/generated/ZiFetchService_grpc_web_pb');
let grpcClient1 = new ZiFetchService1Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient2 = new ZiFetchService2Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient3 = new ZiFetchService3Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient4 = new ZiFetchService4Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient5 = new ZiFetchService5Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient6 = new ZiFetchService6Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient7 = new ZiFetchService7Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient8 = new ZiFetchService8Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient9 = new ZiFetchService9Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient10 = new ZiFetchService10Client(MyConstant.keyList.grpcURL, {}, {});

const grpcClient = new Array(grpcClient1, grpcClient2, grpcClient3, grpcClient4, grpcClient5, grpcClient6, grpcClient7, grpcClient8, grpcClient9, grpcClient10);
const App = () => {
  return (
    <div>
      <ToastContainer />
      <Router>
        <Routes>
          {/* <Route exact path={MyConstant.keyList.projectUrl + "/login"} element={<Login />} /> */}
          <Route path={MyConstant.keyList.projectUrl + "*"} element={<UserRoute grpcClient={grpcClient} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
